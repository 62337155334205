import { createSlice } from '@reduxjs/toolkit';

const toggles = [
  {
    title: 'School Preformance | Math Proficiency Scores',
    column: 'avg_math',
    weight: 0,
    description: 'by Facility (Grades Elementary-High School)',
    use: false
  },
  {
    title: 'School Preformance | Reading Proficiency Scores',
    column: 'avg_reading',
    weight: 10,
    description: 'by Facility (Grades Elementary-High School)',
    use: true
  },
  {
    title: 'School Preformance | Graduation Rates',
    column: 'avg_graduation_rate',
    weight: 5,
    description: 'by Facility (Grades Elementary-High School)',
    use: true
  },
  {
    title: 'Convenient Access to Employment',
    column: 'commute_less_30min',
    weight: 0,
    description: 'Where Commute is 30 Minutes or Less',
    use: false
  },
  {
    title: 'Convenient Access to a Library',
    column: 'total_libraries',
    weight: 10,
    description: 'Within a 3-Mile Raduis of a Library',
    use: true
  },
  {
    title: 'Property Appreciation',
    column: 'property_appreciation_2013_2018',
    weight: 0,
    description: 'Change in Median Home Value From 2013-2018',
    use: false
  },
  {
    title: 'Convenient Access to Public Transportation',
    column: 'total_public_transportation_stops',
    weight: 0,
    description: 'Within a 3-Mile Radius of a Transportation Stop',
    use: false
  },
  {
    title: 'Convenient Access to Community Center',
    column: 'total_community_centers',
    weight: 10,
    description: 'Within a 3-Mile Radius of a Community Center',
    use: true
  },
  {
    title: 'Convenient Access to a Park',
    column: 'total_parks',
    weight: 3,
    description: 'Within a 3-Mile Radius of a Park',
    use: true
  },
  {
    title: 'Convenient Access to a Healthcare Facility',
    column: 'total_medical_facilities',
    weight: 0,
    description: 'Within a 3-Mile Radius of a Healthcare facility (Including Hospitals)',
    use: false
  },
  {
    title: 'Convenient Access to Fresh Produce',
    column: 'total_produce_shops',
    weight: 7,
    description: 'Within a 3-Mile Radius of a Farmers Market, Convience Store or Grocery Store',
    use: true
  }
  // {
  //   title: 'Pedestrians Killed',
  //   column: 'pedestrians_killed',
  //   weight: 10,
  //   description: 'Number of pedestrians killed',
  //   use: true
  // },
  // {
  //   title: 'Roadway Defect',
  //   column: 'roadway_defect',
  //   weight: 3,
  //   description: 'Incidents that included a roadway defect',
  //   use: true
  // },
  // {
  //   title: 'Crash Near Intersection',
  //   column: 'crash_near_intersection',
  //   weight: 5,
  //   description: 'Crashes that took place near an intersection',
  //   use: true
  // },
  // {
  //   title: 'Traffic Controll Issue',
  //   column: 'traffic_control_issue',
  //   weight: 5,
  //   description: 'Crashes that had a traffic control issue (i.e. broken stop light)',
  //   use: true
  // },
  // {
  //   title: 'Alcohol Involved',
  //   column: 'alcohol_involved',
  //   weight: 5,
  //   description: 'Crashes that involved alcohol',
  //   use: true
  // },
  // {
  //   title: 'Bike Involved',
  //   column: 'bike_involved',
  //   weight: 0,
  //   description: 'Crashes that involved a bicycle',
  //   use: false
  // },
  // {
  //   title: 'Distraction Involved',
  //   column: 'distraction_involved',
  //   weight: 7,
  //   description: 'Crashes that involved a distracted driver',
  //   use: true
  // },
  // {
  //   title: 'Pedestrian Involved',
  //   column: 'pedestrian_involved',
  //   weight: 0,
  //   description: 'Crashes that involved a pedestrian',
  //   use: false
  // },
  // {
  //   title: 'School Zone',
  //   column: 'school_zone',
  //   weight: 10,
  //   description: 'Crashes that took place in a school zone',
  //   use: true
  // }
]

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    bottomSheetOpen: false,
    toggles: toggles,
    processIndex: false
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setClick: (state, action) => {
      state.processIndex = action.payload
      console.log(action.payload)

    },
    setWeight: (state, action) => {
      const objIndex = state.toggles.findIndex(i => i.column === action.payload[0]);
      const updatedObj = { ...state.toggles[objIndex], weight: action.payload[1]};
      console.log(action.payload)
      const updatedData = [
        ...state.toggles.slice(0, objIndex),
        updatedObj,
        ...state.toggles.slice(objIndex + 1),
      ];
      console.log(updatedData)
      
      state.toggles = updatedData
    },
    setInclude: (state, action) => {
      console.log(action.payload)
      const objIndex = state.toggles.findIndex(i => i.column === action.payload[0]);
      const updatedObj = { ...state.toggles[objIndex], weight: action.payload[1]};
      console.log(objIndex)
      const updatedData = [
        ...state.toggles.slice(0, objIndex),
        updatedObj,
        ...state.toggles.slice(objIndex + 1),
      ];
      console.log(updatedData)

      state.toggles = updatedData
    }
  },
});

export default slice.reducer;

export const setError = (payload) => ({ type: 'app/setError', payload });
export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});
export const setWeight = (payload) => ({ type: 'app/setWeight', payload });
export const setInclude = (payload) => ({ type: 'app/setInclude', payload });
export const setClick = (payload) => ({ type: 'app/setClick', payload });
