import { VOYAGER, POSITRON } from '@carto/react-basemaps';
import { API_VERSIONS } from '@deck.gl/carto';

export const initialState = {
  viewState: {
    latitude: 47.59495450197339,
    longitude:  -122.3276140673321,
    zoom: 10,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: POSITRON,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: 'https://gcp-us-east1.api.carto.com',
    accessToken:'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfN3hoZnd5bWwiLCJqdGkiOiJmZjUxZjg4MiJ9.4Ewak5paiYph9DKkYt18Xn7gWiPQLyu29T6DDIW2wac',
  },
  googleApiKey: '', // only required when using a Google Basemap,
  // oauth: {
  //   domain: 'auth.carto.com',
  //   // Type here your application client id
  //       // clientId: 'vYdNr5IcUd9YhR5eRsOi2u7uG6D8LFYA',//real deal
  //       clientId: 'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfN3hoZnd5bWwiLCJqdGkiOiJmZjUxZjg4MiJ9.4Ewak5paiYph9DKkYt18Xn7gWiPQLyu29T6DDIW2wac',//test

  //       // clientId: 'rKLiuPwpyi4zKHpTGmDOWo4Tsv8t9nzR',// only for testing
  //       // only  testing
  //   scopes: [
  //     'read:current_user',
  //     'update:current_user',
  //     'read:connections',
  //     'write:connections',
  //     'read:maps',
  //     'write:maps',
  //     'read:account',
  //     'admin:account',
  //   ],
  //   audience: 'carto-cloud-native-api',
  //   authorizeEndPoint: 'https://carto.com/oauth2/authorize', // only valid if keeping https://localhost:3000/oauthCallback
  // },
};
